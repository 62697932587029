/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.subcategories {
  margin: 20px 0;
  display: flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.subcategories .subcategory {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  margin: 5px;
  padding: 0;
  width: calc(50% - 10px);
  position: relative;
  background-color: #fff;
}

@media screen and (min-width: 430px) {
  .subcategories .subcategory {
    width: calc(33.33333% - 10px);
  }
}

@media screen and (min-width: 992px) {
  .subcategories .subcategory {
    margin: 10px;
    width: calc(25% - 20px);
  }
  .subcategories .subcategory:hover .category-image img {
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
  }
}

.subcategories .subcategory .category-image {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
}

.subcategories .subcategory .category-image > a {
  display: block;
}

.subcategories .subcategory .category-image > a img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

@media screen and (min-width: 992px) {
  .subcategories .subcategory .category-image > a img {
    transform: scale(1);
  }
}

.subcategories .subcategory .category-content {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
}

@media screen and (min-width: 992px) {
  .subcategories .subcategory .category-content {
    font-size: 16px;
    position: relative;
    left: 0;
    margin: 0;
  }
}

.cat-column {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
  align-self: stretch;
}

.cats .cat-column.pagebuilder-column.image-text-box [data-content-type=text] {
  display: flex;
  align-items: center;
  flex: 1;
}

.cats .pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
  border-radius: 0;
  min-height: 300px;
  background-color: transparent;
}

.product-table-container[role="table"] {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  background-color: #FAFAFA;
}

.product-table-container[role="table"] .product-table-inner {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
  max-width: 1310px;
}

@media screen and (min-width: 992px) {
  .product-table-container[role="table"] .product-table-inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.product-table-container[role="table"] .flex-table {
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.product-table-container[role="table"] .flex-table.header {
  border-bottom: 10px solid #000;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  padding-top: 0;
}

@media screen and (min-width: 992px) {
  .product-table-container[role="table"] .flex-table.header {
    font-size: 1rem;
  }
}

.product-table-container[role="table"] .flex-table.row {
  border-bottom: 1px solid #000;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

@media screen and (min-width: 992px) {
  .product-table-container[role="table"] .flex-table.row {
    font-size: 1rem;
  }
}

.product-table-container[role="table"] .flex-row {
  width: 30%;
}

.product-table-container[role="table"] .flex-row.first {
  width: 70%;
  padding-right: 1rem;
}

.product-table-container[role="table"] .flex-row.center {
  text-align: center;
  justify-content: center;
  align-items: center;
}
