//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//

//------------------------------------//
// Magesparrow - Blank Theme styles   //
//------------------------------------//
// Do not remove this.
@import "magesparrow-blank/CtiDigital_QtyStepper/web/css/module";
//------------------------------------//
$table-header: map-get($theme-colors, 'primary');
$table-header-border: map-get($theme-colors, 'light');
$table-border: map-get($theme-colors, 'light');
$row-bg: #f4f2f1;
$transition: transform 0.3s ease-in-out;

.subcategories {
    margin: 20px 0;
    display:flex;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    .subcategory {
        display:flex;
        display: -webkit-flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        margin: 5px;
        padding: 0;
        width: calc(50% - 10px);
        position: relative;
        background-color: $white;

        @include respond-to-up(smm) {
            width: calc(33.33333% - 10px);
        }

        @include respond-to-up($tweakpoint-nav-toggle) {
            margin: 10px;
            width: calc(25% - 20px);

            &:hover {
                .category-image img {
                    transform: scale(0.9);
                    transition: $transition;
                }
            }
        }

        .category-image {
            position: relative;
            padding-bottom: 100%;
            height: 0;
            overflow: hidden;

            > a {
                display: block;


                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    transition: $transition;

                    @include respond-to-up($tweakpoint-nav-toggle) {
                        transform: scale(1);
                    }
                }
            }
        }

        .category-content {
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            font-weight: bold;
            font-size: 13px;
            text-align: center;
            margin-top: 10px;
            padding: 10px;

            @include respond-to-up($tweakpoint-nav-toggle) {
                font-size: 16px;
                position: relative;
                left: 0;
                margin: 0;
            }
        }
    }
}

.cat-column {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 33.3333%;
    padding-left: 15px;
    padding-right: 15px;
    align-self: stretch;
}

.cats .cat-column.pagebuilder-column.image-text-box [data-content-type=text] {
    display:flex;
    align-items: center;
    flex:1;
}
.cats .pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
    border-radius: 0;
    min-height: 300px;
    background-color: transparent;
}
.product-table-container[role="table"] {
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    background-color: #FAFAFA;

    .product-table-inner {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 30px;
        padding-bottom: 30px;
        margin: 0 auto;
        max-width: $max-width;

        @include respond-to-up($tweakpoint-nav-toggle) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    .flex-table {
        display: flex;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        &.header {
            border-bottom: 10px solid $black;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: -0.02em;
            padding-top: 0;

            @include respond-to-up($tweakpoint-nav-toggle) {
                font-size: 1rem;
            }
        }

        &.row {
            border-bottom: 1px solid $black;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -0.02em;

            @include respond-to-up($tweakpoint-nav-toggle) {
                font-size: 1rem;
            }
        }
    }

    .flex-row {
        width: 30%;

        &.first {
            width: 70%;
            padding-right: 1rem;
        }

        &.center {
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }
}


